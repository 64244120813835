import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {Button, Table} from "antd";
import querystring from "query-string";

import {FaqModel} from "../../utils/models/faqModel";
import {useGetFaq} from "./services/queries";
import {rootPaths} from "../../routing/root/rootPaths";
import {useDeleteFaq} from "./services/mutations";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import PlusIcon from "../../assets/icons/PlusIcon";
import FaqActions from "./components/actions/FaqActions";

import styles from "./index.module.scss";

function Index() {
  const {data} = useGetFaq();
  const navigate = useNavigate();
  const location = useLocation();
  const deleteFaq = useDeleteFaq();

  function handlePage(page: number) {
    const searchParams = querystring.parse(location.search);
    const newSearchParams = searchParams;
    newSearchParams.page = String(page);
    navigate({search: querystring.stringify(newSearchParams)});
  }

  function handleEdit(id: number) {
    navigate(`${rootPaths.FAQ}/${id}`);
  }

  function handleDelete(id: number) {
    deleteFaq.mutate(id);
  }

  const columns: ColumnsType<FaqModel> = [
    {
      title: "Имя файла",
      dataIndex: "question_uz",
    },
    {
      title: "Tavsif",
      dataIndex: "answer_uz",
      ellipsis: true
    },
    {
      render: (record) => <FaqActions id={record.id}/>,
    },
  ];

  function onPrevPage() {
    navigate(-1);
  }

  function handleAddNew() {
    navigate(location.pathname + "/new");
  }

  return (
      <div className={styles.faq}>
        <div className={styles.header}>
          <div className={styles.header_left}>
            <div onClick={onPrevPage}>
              <ArrowLeft/>
            </div>
            <span>FAQ</span>
          </div>
          <div className={styles.header_right}>
            <Button type="primary" onClick={handleAddNew}>
              <PlusIcon/>
              <span>Yangi yaratish</span>
            </Button>
          </div>
        </div>
        <div className={styles.body}>
          <Table
              columns={columns}
              dataSource={data?.data}
              className={styles.body_table}
              rowKey={(record) => record.id}
              loading={!data}
              pagination={{
                pageSize: 9,
                total: data?.total,
                onChange: handlePage,
              }}
          />
        </div>
      </div>
  );
}

export default Index;
