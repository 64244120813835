import React, {useState} from 'react';
import Dropzone from "react-dropzone";
import {FormInstance, Modal} from "antd";
import ReactPlayer from 'react-player';

import DownloadPercentageIcon from "../../../../assets/icons/DownloadPercentageIcon";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import UploadIcon from "../../../../assets/icons/UploadIcon";

import styles from "../../index.module.scss";

interface IProps {
  form: FormInstance<any>
  name: string
  data?: {
    name: string
    path: string
  }
}

const Video: React.FC<IProps> = ({form, name, data}) => {
  const [value, setValue] = useState<File>()
  const [isModalOpen, setIsModalOpen] = useState(false);

  //handle drop
  function handleDrop(files: any) {
    setValue(files[0])
    form.setFieldsValue({
      [name]: files[0]
    })
  }

  // show modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // handle ok
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // handle cancel
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // video name
  const videoName = value?.name || data?.name

  // video size
  const videoSize = value ? (value.size / (1024 * 1024)).toFixed(2) : null

  console.log(value, data)

  return (
    <div>
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept={{
          "image/*": [".jpg", ".png", ".svg"],
          "video/*": [".mp4", ".avi", ".3gp", ".ts", ".mov"],
        }}
      >
        {({getRootProps, getInputProps}) => (
          <div
            {...getRootProps()}
            className={styles.add_new_top_upload_video}
          >
            <input {...getInputProps()} style={{display: "none"}}/>
            <div className={styles.add_new_top_upload_video_item}>
              <UploadIcon/>
              <div className={styles.center}>
                <p>Yuklash uchun bosing</p>
                <span>yoki sudrab olib tashlang</span>
              </div>
              <p>MP4, AVI, JPG, PNG, SVG, 3GP, TS, MOV</p>
            </div>
          </div>
        )}
      </Dropzone>

      {(value || data) && <div className={styles.add_new_top_percentage} onClick={showModal}>
          <div className={styles.add_new_top_percentage_item}>
              <div className={styles.center}>
                  <DownloadPercentageIcon/>
                  <div className={styles.center_item}>
                      <p>{videoName}</p>
                    {videoSize && (
                      <span>{videoSize} MB</span>
                    )}
                  </div>
              </div>
              <TrashIcon/>
          </div>
      </div>
      }

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        centered
        width={800}
        className={styles.modal}
      >
        {value ? (
          <video
            width={'100%'}
            controls
            src={value && URL.createObjectURL(value)}
            className={styles.video}
          />
        ) : (
          <ReactPlayer
            playing
            width={'100%'}
            controls
            url={data?.path}
          />
        )}
      </Modal>
    </div>
  );
}

export default Video;