import {useMutation, useQueryClient} from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/constant/endPoints";
import {queryKeys} from "../utils/constant/queryKeys";

export function useDeleteFaq() {
    const qc = useQueryClient()

    return useMutation(
        async (id: number) => {
            const res = await $api.delete(`${endPoints.FAQ_DELETE}?id=${id}`)
            return res.data
        }, {
            onSuccess: () => {
                qc.invalidateQueries([queryKeys.FAQ])
            }
        }
    )
}