import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/constant/endPoints";
import {rootPaths} from "../../../routing/root/rootPaths";
import {message} from "antd";

export function useCreateCategory() {
   const navigate = useNavigate()

   return useMutation(
      async (req: {
         name_uz: string,
         name_ru: string,
         description_uz: string,
         description_ru: string,
         place: number,
      }) => {
         req.place = 1;
         const res = await $api.post(endPoints.DETAILED_CATEGORY_CREATE, req)
         return res.data
      }, {
         onSuccess: (res) => {
            navigate(rootPaths.CATEGORIES)
         }, onError: () => {
            message.error("Error")
         }
      }
   )
}

export function useEditMutations() {
   const navigate = useNavigate();

   return useMutation(
      async (req: {
         id: number,
         name_uz: string,
         name_ru: string,
         description_uz: string,
         description_ru: string,
         place: number
      }) => {
         req.place = 1;
         const res = await $api.put(endPoints.DETAILED_CATEGORY_UPDATE, req)
         return res.data
      }, {
         onSuccess: (res) => {
            navigate(rootPaths.CATEGORIES)
         }, onError: () => {
            message.error('Error')
         }
      }
   )
}