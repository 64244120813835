import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";

type Props = {
    fontSize?: number;
};

const Spinner = ({fontSize}: Props) => (
    <Spin
        indicator={<LoadingOutlined style={{fontSize: fontSize || 24}} spin/>}
    />
);

export default Spinner;
