import {useLocation} from "react-router-dom";
import {endPoints} from "../utils/const/endPoints";
import querystring from "query-string";
import {useQuery} from "@tanstack/react-query";
import {Pagination} from "../../../utils/models/paginationType";
import {PostsModel} from "../../../utils/models/postsModel";
import {queryKeys} from "../utils/const/queryKeys";
import $api from "../../../services/RequestService";
import {message} from "antd";

export function useGetPost() {
    const location = useLocation()
    let url = endPoints.POST
    const searchParams = querystring.parse(location.search)
    url += `?${querystring.stringify({...searchParams, size: 10})}`

    return useQuery<Pagination<PostsModel>>(
        [queryKeys.POST, url],
        async () => {
            const res = await $api.get(url)
            return res.data
        }, {
            onError: () => {
                message.error('Error')
            }
        }
    )
}