import React, {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {Button, Form, Input, Spin, Tabs, TabsProps} from "antd";

import {DetailedCategoryModel} from "../../utils/models/detailedCategoryModel";
import {useCreateCategory, useEditMutations} from "./services/mutations";
import {useGetOneCategory} from "./services/queries";
import ArrowLeft from "../../assets/icons/ArrowLeft";

import styles from "./index.module.scss";

function Index() {
  const [activeKay, setActiveKay] = useState("1");
  const createCategory = useCreateCategory();
  const editCategory = useEditMutations();
  const navigate = useNavigate();
  const params = useParams();
  const {data, isLoading, remove} = useGetOneCategory(params?.id!);
  const [form] = Form.useForm();

  // is creating
  const isCreating = params.id === "new";

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
    }
  }, [data, form]);

  useEffect(() => {
    return () => {
      remove()
      form.resetFields()
    }
  }, [form, remove])

  // page title
  const pageTitle = isCreating ? "Yangi yaratish" : data?.name_uz;

  // edit and new
  const onFinish = (values: DetailedCategoryModel) => {
    if (isCreating) {
      createCategory.mutate(values);
    } else {
      editCategory.mutate({...values, id: Number(params.id)});
    }
  };

  // finish fail
  const onFinishFailed = (values: DetailedCategoryModel) => {
    if (values?.name_uz && values?.description_uz) {
      setActiveKay("2");
    } else {
      setActiveKay("1");
    }
  };

  // back
  function onPrevPage() {
    navigate(-1);
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `O’zbekcha`,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name={"name_uz"}
            rules={[{required: true, message: ""}]}
            label="Sarlavha"
            className={styles.add_new_tab_first}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="description_uz"
            rules={[{required: true, message: ""}]}
            label="Tavsif"
            className={styles.add_new_tab_first}
          >
            <TextArea rows={10}/>
          </Form.Item>
        </>
      ),
    },
    {
      key: "2",
      label: `Русский`,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name={"name_ru"}
            rules={[{required: true, message: ""}]}
            label="Заголовок"
            className={styles.add_new_tab_second}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name={"description_ru"}
            rules={[{required: true, message: ""}]}
            label="Описание"
            className={styles.add_new_tab_second}
          >
            <TextArea rows={10}/>
          </Form.Item>
        </>
      ),
    },
  ];
  return (
    <div className={styles.add_new}>
      <div className={styles.header}>
        <div className={styles.header_left}>
          <div onClick={onPrevPage}>
            <ArrowLeft/>
          </div>
          <span>{pageTitle}</span>
        </div>
      </div>
      <div className={styles.body}>
        <Spin spinning={isLoading && !isCreating} className={styles.spin}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={(e) => onFinishFailed(e.values)}

          >
            <Tabs
              defaultActiveKey="1"
              items={items}
              activeKey={activeKay}
              onChange={(e) => setActiveKay(e)}
              className={styles.add_new_tab}
            />
            <div className={styles.add_new_buttons}>
              <Button className={styles.cancel}>Bekor qilish</Button>
              <Button
                className={styles.submit}
                htmlType="submit"
                loading={createCategory.isLoading || editCategory.isLoading}
              >
                Chop etish
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </div>

  );
}

export default Index;
