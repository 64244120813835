import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {endPoints} from "../utils/constant/endPoints";
import {rootPaths} from '../../../routing/root/rootPaths'
import $api from '../../../services/RequestService'
import {message} from "antd";

export function useCreateFaq() {
    const navigate = useNavigate()

    return useMutation(
        async (req: {
            question_uz: string,
            question_ru: string,
            answer_uz: string,
            answer_ru: string,
            status: string,
            place: number
        }) => {
            req.place = 1;
            req.status = 'active';
            const res = await $api.post(endPoints.DETAILED_FAQ_CREATE, req)
            return res.data
        }, {
            onSuccess: (res) => {
                navigate(rootPaths.FAQ)
            }, onError: () => {
                message.error('Error')
            }
        }
    )
}

export function useEditMutations(){
    const navigate = useNavigate();

    return useMutation(
        async (req: {
            id: number,
            question_uz: string,
            question_ru: string,
            answer_uz: string,
            answer_ru: string,
            status: string,
            place: number
        }) => {
            req.place = 1;
            const res = await $api.put(endPoints.DETAILED_FAQ_UPDATE, req)
            return res.data
        }, {
            onSuccess: (res) => {
                navigate(rootPaths.FAQ)
            }, onError: () => {
                message.error('Error')
            }
        }
    )
}