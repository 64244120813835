import React from 'react';

function DownloadPercentageIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="32" height="32" rx="16" fill="#88EBDB"/>
      <g clipPath="url(#clip0_463_14579)">
        <path
          d="M14.6666 11.3333V24.6666M21.3333 11.3333V24.6666M11.3333 17.9999H24.6666M11.3333 14.6666H14.6666M11.3333 21.3333H14.6666M21.3333 21.3333H24.6666M21.3333 14.6666H24.6666M12.7866 11.3333H23.2133C24.0159 11.3333 24.6666 11.9839 24.6666 12.7866V23.2133C24.6666 24.0159 24.0159 24.6666 23.2133 24.6666H12.7866C11.9839 24.6666 11.3333 24.0159 11.3333 23.2133V12.7866C11.3333 11.9839 11.9839 11.3333 12.7866 11.3333Z"
          stroke="#344054" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <rect x="2" y="2" width="32" height="32" rx="16" stroke="#F1F4FF" strokeWidth="4"/>
      <defs>
        <clipPath id="clip0_463_14579">
          <rect width="16" height="16" fill="white" transform="translate(10 10)"/>
        </clipPath>
      </defs>
    </svg>

  );
}

export default DownloadPercentageIcon;