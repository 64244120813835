import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {endpoints} from "../utils/endpoints";
import {rootPaths} from "../../../routing/root/rootPaths";
import {message} from "antd";
import $api from "../../../services/RequestService";

export function useLogin() {
  const navigate = useNavigate();

  return useMutation(
    async (req: { email: string; password: string }) => {
      const res = await $api.post(endpoints.LOGIN, req);
      return res.data;
    },
    {
      onSuccess: (res) => {
        localStorage.setItem("token", res?.Authorization);
        localStorage.setItem("user", JSON.stringify(res?.user))
        navigate(rootPaths.CATEGORIES);
      },
      onError: () => {
        message.error("Error");
      },
    }
  );
}
