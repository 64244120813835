import React from 'react';

const PostsIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 12.0001L12.1422 16.8212C12.2734 16.8868 12.339 16.9196 12.4078 16.9325C12.4687 16.9439 12.5313 16.9439 12.5922 16.9325C12.661 16.9196 12.7266 16.8868 12.8578 16.8212L22.5 12.0001M2.5 17.0001L12.1422 21.8212C12.2734 21.8868 12.339 21.9196 12.4078 21.9325C12.4687 21.9439 12.5313 21.9439 12.5922 21.9325C12.661 21.9196 12.7266 21.8868 12.8578 21.8212L22.5 17.0001M2.5 7.00006L12.1422 2.17895C12.2734 2.11336 12.339 2.08056 12.4078 2.06766C12.4687 2.05622 12.5313 2.05622 12.5922 2.06766C12.661 2.08056 12.7266 2.11336 12.8578 2.17895L22.5 7.00006L12.8578 11.8212C12.7266 11.8868 12.661 11.9196 12.5922 11.9325C12.5313 11.9439 12.4687 11.9439 12.4078 11.9325C12.339 11.9196 12.2734 11.8868 12.1422 11.8212L2.5 7.00006Z"
                stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    );
};

export default PostsIcon;