import React from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteFaq } from "../../services/mutations";
import { rootPaths } from "../../../../routing/root/rootPaths";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import EditIcon from "../../../../assets/icons/EditIcon";
import styles from "../../index.module.scss";
import Spinner from "../../../../common/spinner/Spinner";

type Props = {
  id: number;
};

const FaqActions = ({ id }: Props) => {
  const navigate = useNavigate();
  const deleteFaq = useDeleteFaq();

  function handleEdit() {
    navigate(`${rootPaths.FAQ}/${id}`);
  }

  function handleDelete() {
    deleteFaq.mutate(id);
  }

  return (
    <div className={styles.body_table_end}>
      {deleteFaq.isLoading === true ? (
        <Spinner />
      ) : (
        <DeleteIcon onClick={handleDelete} />
      )}
      <EditIcon onCLick={handleEdit} />
    </div>
  );
};

export default FaqActions;
