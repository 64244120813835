import React from "react";
import {useNavigate} from "react-router-dom";

import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import EditIcon from "../../../../assets/icons/EditIcon";
import {useDeleteCategories} from "../../services/mutations";
import {rootPaths} from "../../../../routing/root/rootPaths";
import Spinner from "../../../../common/spinner/Spinner";

import styles from "../../index.module.scss";

type Props = {
    id: number;
};

const CategoriesActions = ({id}: Props) => {
    const navigate = useNavigate();
    const deleteCategories = useDeleteCategories();

    function handleEdit() {
        navigate(`${rootPaths.CATEGORIES}/${id}`);
    }

    function handleDelete() {
        deleteCategories.mutate(id);
    }

    return (
        <div className={styles.body_table_end}>
            {deleteCategories.isLoading === true ? (
                <Spinner/>
            ) : (
                <DeleteIcon onClick={handleDelete}/>
            )}

            <EditIcon onCLick={handleEdit}/>
        </div>
    );
};

export default CategoriesActions;
