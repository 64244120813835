import React from "react";
import {Button, Form, Input} from "antd";

import {useLogin} from "./services/mutations";
import UysotLogo from "../../assets/images/UysotLogo";
import background from "../../assets/images/LoginBackground.png";

import styles from "./index.module.scss";

function Index() {
  const login = useLogin();
  const onFinish = (value: { email: string; password: string }) => {
    login.mutate(value);
  };
  return (
    <div className={styles.login}>
      <div className={styles.login_left}>
        <div className={styles.login_left_top}>
          <UysotLogo/>
          <h3>Xush kelibsiz!</h3>
          <p>Ma'lumotlaringizni kiriting</p>
        </div>
        <div className={styles.login_left_bottom}>
          <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="email"
              label="Email"
              className={styles.login_left_bottom_item}
              rules={[{required: true, message: "Loginni kiriting"}]}
            >
              <Input className={styles.input} placeholder="Loginni kiriting"/>
            </Form.Item>
            <Form.Item
              name="password"
              label="Parol"
              className={styles.login_left_bottom_item}
              rules={[{required: true, message: "Parolni kiriting"}]}
            >
              <Input.Password
                className={styles.input}
                placeholder="Parolni kiriting"
              />
            </Form.Item>
            <Button
              type="default"
              htmlType="submit"
              className={styles.login_left_bottom_button}
              loading={login.isLoading}
            >
              Kirish
            </Button>
          </Form>
        </div>
      </div>
      <div className={styles.login_right}>
        <img src={background} alt=""/>
      </div>
    </div>
  );
}

export default Index;
