import React from "react";
import { useNavigate } from "react-router-dom";

import { useDeletePosts } from "../../services/mutations";
import { rootPaths } from "../../../../routing/root/rootPaths";
import Spinner from "../../../../common/spinner/Spinner";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import EditIcon from "../../../../assets/icons/EditIcon";

import styles from "../../index.module.scss";

type Props = {
  id: number;
};

const PostAction = ({ id }: Props) => {
  const navigate = useNavigate();
  const deletePost = useDeletePosts();

  function handleEdit() {
    navigate(`${rootPaths.POSTS}/${id}`);
  }

  function handleDelete() {
    deletePost.mutate(id);
  }
  return (
    <div className={styles.body_table_end}>
      {deletePost.isLoading === true ? (
        <Spinner />
      ) : (
        <DeleteIcon onClick={handleDelete} />
      )}

      <EditIcon onCLick={handleEdit} />
    </div>
  );
};

export default PostAction;
