import {useQuery} from "@tanstack/react-query";
import {useLocation} from "react-router-dom";
import querystring from "query-string";
import {message} from "antd";

import {queryKeys} from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/constant/endPoints";
import {Pagination} from "../../../utils/models/paginationType";
import {CategoryModel} from "../../../utils/models/categoryModel";

export function useGetCategories() {
    const location = useLocation()
    let url = endPoints.CATEGORIES
    // paginationda kk buladigan kod bu
    const searchParams = querystring.parse(location.search)
    url += `?${querystring.stringify({...searchParams, size: 10})}`

    return useQuery<Pagination<CategoryModel>>(
        [queryKeys.CATEGORIES, url],
        async () => {
            const res = await $api.get(url)
            return res.data
        }, {
            onError: () => {
                message.error('Error')
            }
        }
    )
}

