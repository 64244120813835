import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import querystring from "query-string";
import {ColumnsType} from "antd/es/table";
import {Button, Table} from "antd";

import {PostsModel} from "../../utils/models/postsModel";
import {useGetPost} from "./services/queries";
import PostAction from "./components/actions/PostActions";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import PlusIcon from "../../assets/icons/PlusIcon";

import styles from "./index.module.scss";

function Index() {
  const {data} = useGetPost();
  const navigate = useNavigate();
  const location = useLocation();

  // onchange pagination
  function handlePage(page: number) {
    const searchParams = querystring.parse(location.search);
    const newSearchParams = searchParams;
    newSearchParams.page = String(page);
    navigate({search: querystring.stringify(newSearchParams)});
  }

  // columns
  const columns: ColumnsType<PostsModel> = [
    {
      title: "Имя файла",
      dataIndex: "name_uz",
    },
    {
      render: (record) => <PostAction id={record.id}/>,
    },
  ];

  // back
  function onPrevPage() {
    navigate(-1);
  }

  // add new  
  function handleAddNew() {
    navigate(location.pathname + "/new");
  }

  return (
    <div className={styles.posts}>
      <div className={styles.header}>
        <div className={styles.header_left}>
          <div onClick={onPrevPage}>
            <ArrowLeft/>
          </div>
          <span>Yangiliklar</span>
        </div>
        <div className={styles.header_right}>
          <Button type="primary" onClick={handleAddNew}>
            <PlusIcon/>
            <span>Yangi yaratish</span>
          </Button>
        </div>
      </div>
      <div className={styles.body}>
        <Table
          columns={columns}
          dataSource={data?.data}
          className={styles.body_table}
          rowKey={(record) => record.id}
          loading={!data}
          onRow={(record) => {
            return {
              onClick: () => {
                console.log(
                  "https://backend.help.uysot.uz/public/" +
                  record?.videouz_path +
                  "/video_720p.mp4",
                  record
                );
              },
            };
          }}
          pagination={{
            pageSize: 9,
            total: data?.total,
            onChange: handlePage,
          }}
        />
      </div>
    </div>
  );
}

export default Index;
