import React, {useState, useEffect} from "react";
import {Tabs, TabsProps, Button, Form, Input, Spin} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useCreateFaq, useEditMutations} from "./services/mutations";
import {DetailedFaqModel} from "../../utils/models/detailedFaqModel";
import {useGetOneCategory} from "./services/queries";
import {useParams, useNavigate} from "react-router-dom";
import styles from "./index.module.scss";
import ArrowLeft from "../../assets/icons/ArrowLeft";

function Index() {
  const [activeKey, setActiveKey] = useState("1");
  const createFaq = useCreateFaq();
  const editFaq = useEditMutations();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const {data, isLoading, remove} = useGetOneCategory(String(params?.id!));

  const isCreating = params.id === "new";

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
    }
  }, [data, form]);

  useEffect(() => {
    return () => {
      remove()
      form.resetFields()
    }
  }, [form, remove])

  const pageTitle = isCreating ? "Yangi yaratish" : data?.question_uz;

  const onFinish = (values: DetailedFaqModel) => {
    if (isCreating) {
      createFaq.mutate(values);
    } else {
      editFaq.mutate({...values, id: Number(params.id)});
    }
  };

  const onFinishFailed = (values: DetailedFaqModel) => {
    console.log(values);
    if (values?.question_uz && values?.answer_uz) {
      setActiveKey("2");
    } else {
      setActiveKey("1");
    }
  };

  function onPrevPage() {
    navigate(-1);
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `O’zbekcha`,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name={"question_uz"}
            rules={[{required: true, message: ""}]}
            label="Savol"
            className={styles.add_new_tab_first}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="answer_uz"
            rules={[{required: true, message: ""}]}
            label="Javob"
            className={styles.add_new_tab_first}
          >
            <TextArea rows={10}/>
          </Form.Item>
        </>
      ),
    },
    {
      key: "2",
      label: `Русский`,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name={"question_ru"}
            rules={[{required: true, message: ""}]}
            label="Вопрос"
            className={styles.add_new_tab_second}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name={"answer_ru"}
            rules={[{required: true, message: ""}]}
            label="Отвечать"
            className={styles.add_new_tab_second}
          >
            <TextArea rows={10}/>
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div className={styles.add_new}>
      <div className={styles.header}>
        <div className={styles.header_left}>
          <div onClick={onPrevPage}>
            <ArrowLeft/>
          </div>
          <span>{pageTitle}</span>
        </div>
      </div>
      <div className={styles.body}>
        <Spin spinning={isLoading && !isCreating}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={(e) => onFinishFailed(e.values)}
          >
            <Tabs
              defaultActiveKey="1"
              items={items}
              className={styles.add_new_tab}
              activeKey={activeKey}
              onChange={(e) => setActiveKey(e)}
            />
            <div className={styles.add_new_tab_buttons}>
              <Button className={styles.cancel}>Bekor qilish</Button>
              <Button
                className={styles.submit}
                htmlType="submit"
                loading={createFaq.isLoading || editFaq.isLoading}
              >
                Chop etish
              </Button>
            </div>
          </Form>
        </Spin>

      </div>
    </div>
  );
}

export default Index;
