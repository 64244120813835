import React, {ReactNode} from "react";

import styles from "./mainLayout.module.scss";
import UysotLogo from "../assets/images/UysotLogo";
import CategoriesIcon from "../assets/icons/CategoriesIcon";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {rootPaths} from "../routing/root/rootPaths";
import PostsIcon from "../assets/icons/PostsIcon";
import FaqIcon from "../assets/icons/FaqIcon";
import LogoutIcon from "../assets/icons/LogoutIcon";
import Image from "../assets/images/images.png";

type Props = { children: ReactNode };

function MainLayout(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  // loginni alohida ochish uchun
  if (location.pathname === rootPaths.INDEX) {
    return props.children as JSX.Element;
  }

  // active
  function activeClass(path: string) {
    if (location.pathname.includes(path)) {
      return styles.active;
    }
  }

  // log out login
  function exitPage() {
    navigate(rootPaths.INDEX);
    localStorage.clear();
  }

  // localstoragedan userni olish
  const user = JSON.parse(localStorage.getItem('user') || "")

  return (
    <div className={styles.main_layout}>
      <div className={styles.navbar}>
        <div className={styles.navbar_top}>
          <UysotLogo/>
          <span>Uysot</span>
        </div>
        <div className={styles.navbar_center}>
          <Link
            to={rootPaths.CATEGORIES}
            className={`${styles.navbar_center_item} ${activeClass(
              rootPaths.CATEGORIES
            )}`}
          >
            <CategoriesIcon/>
            <span>Kategoriya</span>
          </Link>
          <Link
            to={rootPaths.POSTS}
            className={`${styles.navbar_center_item} ${activeClass(
              rootPaths.POSTS
            )}`}
          >
            <PostsIcon/>
            <span>Yangilik</span>
          </Link>
          <Link
            to={rootPaths.FAQ}
            className={`${styles.navbar_center_item} ${activeClass(
              rootPaths.FAQ
            )}`}
          >
            <FaqIcon/>
            <span>FAQ</span>
          </Link>
        </div>
        <div className={styles.navbar_bottom}>
          <div className={styles.navbar_bottom_item}>
            <div className={styles.navbar_bottom_left}>
              <img src={Image} alt=""/>
            </div>
            <div className={styles.navbar_bottom_right}>
              <div className={styles.navbar_bottom_right_top}>
                <span>{user.name}</span>
                <Link
                  to={rootPaths.INDEX}
                  className={styles.link}
                  onClick={exitPage}
                >
                  <LogoutIcon/>
                </Link>
              </div>
              <div className={styles.navbar_bottom_right_bottom}>
                <span>{user.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.body}>{props.children}</div>
      </div>
    </div>
  );
}

export default MainLayout;
