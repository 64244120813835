import {useQuery} from "@tanstack/react-query";
import {queryKeys} from "../utils/constant/queryKeys";
import {endPoints} from "../utils/constant/endPoints";
import $api from "../../../services/RequestService";
import {message} from "antd";
import {CategoryModel} from "../../../utils/models/categoryModel";
import {PostsModel} from "../../../utils/models/postsModel";

export function useGetCategorySelect() {
  return useQuery<CategoryModel[]>(
    [queryKeys.CATEGORY_SELECT],
    async () => {
      const res = await $api.get(endPoints.CATEGORIES_SELECT);
      return res.data;
    },
    {
      onError: () => {
        message.error("Error");
      },
    }
  );
}

export function useGetOnePost(id: string) {
  return useQuery<PostsModel>(
    [queryKeys.DETAILED_POSTS, id],
    async () => {
      const res = await $api.get(`${endPoints.DETAILED_POST_SHOW}?id=${id}`);
      return res?.data?.posts[0];
    },
    {
      onError: () => {
        message.error("Error");
      },
      enabled: !!+id,
    }
  );
}
