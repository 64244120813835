import { useQuery } from "@tanstack/react-query";
import { FaqModel } from "../../../utils/models/faqModel";
import { queryKeys } from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import { endPoints } from "../utils/constant/endPoints";
import { message } from "antd";

export function useGetOneCategory(id: string) {
  return useQuery<FaqModel>(
    [queryKeys.DETAILED_FAQ],
    async () => {
      const res = await $api.get(`${endPoints.DETAILED_FAQ_SHOW}?id=${id}`);
      return res?.data?.faqs[0];
    },
    {
      onError: () => {
        message.error("Error");
      },
      enabled: !!+id,
    }
  );
}
