import React from 'react';

function ArrowLeft() {
    return (
        <div>
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.25 7.27393L16.25 7.27393" stroke="#667085" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M7.29993 13.2985L1.24993 7.27451L7.29993 1.24951" stroke="#667085" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </div>
    );
}

export default ArrowLeft;