import React from 'react';

const FaqIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 15L8.5 17L13 12.5M8.5 8V5.2C8.5 4.0799 8.5 3.51984 8.71799 3.09202C8.90973 2.71569 9.21569 2.40973 9.59202 2.21799C10.0198 2 10.5799 2 11.7 2H19.3C20.4201 2 20.9802 2 21.408 2.21799C21.7843 2.40973 22.0903 2.71569 22.282 3.09202C22.5 3.51984 22.5 4.0799 22.5 5.2V12.8C22.5 13.9201 22.5 14.4802 22.282 14.908C22.0903 15.2843 21.7843 15.5903 21.408 15.782C20.9802 16 20.4201 16 19.3 16H16.5M5.7 22H13.3C14.4201 22 14.9802 22 15.408 21.782C15.7843 21.5903 16.0903 21.2843 16.282 20.908C16.5 20.4802 16.5 19.9201 16.5 18.8V11.2C16.5 10.0799 16.5 9.51984 16.282 9.09202C16.0903 8.71569 15.7843 8.40973 15.408 8.21799C14.9802 8 14.4201 8 13.3 8H5.7C4.5799 8 4.01984 8 3.59202 8.21799C3.21569 8.40973 2.90973 8.71569 2.71799 9.09202C2.5 9.51984 2.5 10.0799 2.5 11.2V18.8C2.5 19.9201 2.5 20.4802 2.71799 20.908C2.90973 21.2843 3.21569 21.5903 3.59202 21.782C4.01984 22 4.57989 22 5.7 22Z"
                stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    );
};

export default FaqIcon;