import {useMutation, useQueryClient} from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/const/endPoints";
import {queryKeys} from "../utils/const/queryKeys";

export function useDeletePosts() {
    const qc = useQueryClient()

    return useMutation(
        async (id: number) => {
            const res = await $api.delete(`${endPoints.POST_DELETE}?id=${id}`)
            return res.data
        }, {
            onSuccess: () => {
                qc.invalidateQueries([queryKeys.POST])
            }
        }
    )

}