import {rootPaths} from "./rootPaths";
import Login from '../../features/login/Index'
import Categories from '../../features/categories/Index'
import Posts from '../../features/posts/Index'
import Faq from '../../features/faq/Index'
import DetailedCategory from '../../features/detailed-category/Index'
import DetailedFaq from '../../features/detailed-faq/Index'
import DetailedPosts from '../../features/detailed-posts/Index'

export const rootRoutes = [
    {path: rootPaths.INDEX, element: Login},
    {path: rootPaths.CATEGORIES, element: Categories},
    {path: rootPaths.POSTS, element: Posts},
    {path: rootPaths.FAQ, element: Faq},
    {path: rootPaths.DETAILED_CATEGORY, element: DetailedCategory},
    {path: rootPaths.DETAILED_FAQ, element: DetailedFaq},
    {path: rootPaths.DETAILED_POSTS, element: DetailedPosts},
]