import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import { endPoints } from "../utils/constant/endPoints";
import { message } from "antd";
import { CategoryModel } from "../../../utils/models/categoryModel";

export function useGetOneCategory(id: string) {
  return useQuery<CategoryModel>(
    [queryKeys.DETAILED_CATEGORY],
    async () => {
      const res = await $api.get(
        `${endPoints.DETAILED_CATEGORY_SHOW}?id=${id}`
      );
      return res?.data?.categories[0];
    },
    {
      onError: () => {
        message.error("Error");
      },
      enabled: !!+id,
    }
  );
}
