import React, {useState, useEffect} from "react";
import {Button, Form, Input, Select, Spin, Tabs, TabsProps} from "antd";
import {useParams, useNavigate} from "react-router-dom";
import ReactQuill from "react-quill";

import {DetailedPostsModel} from "../../utils/models/detailedPostsModel";
import {useGetCategorySelect, useGetOnePost} from "./services/queries";
import {useCreatePost, useUpdatePost} from "./services/mutations";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import Video from "./components/video/Video";

import "react-quill/dist/quill.snow.css";
import styles from "./index.module.scss";

function Index() {
  const [value, setValue] = useState("");
  const [activeKay, setActiveKay] = useState("1");
  const {data: categories} = useGetCategorySelect();
  const createPosts = useCreatePost();
  const navigate = useNavigate();
  const editPost = useUpdatePost();
  const params = useParams();
  const {data, isLoading, remove} = useGetOnePost(params?.id!);
  const [form] = Form.useForm();
  const modules = {
    toolbar: [
      [{header: [1, 2, false]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {list: "ordered"},
        {list: "bullet"},
        {indent: "-1"},
        {indent: "+1"},
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  // is creating
  const isCreating = params.id === "new";

  // initial form fields
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
    }

  }, [data, form]);


  useEffect(() => {
    return () => {
      remove()
      form.resetFields()
    }
  }, [form, remove])

  // page title
  const pageTitle = isCreating ? "Yangi yaratish" : data?.name_uz;

  // finish
  const onFinish = (values: any) => {
    const formData = new FormData();

    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    formData.append("place", "1");

    if (isCreating) {
      createPosts.mutate(formData);
    } else {
      formData.append("id", params?.id as string);
      editPost.mutate(formData);
    }
  };

  // finish failed
  const onFinishFailed = (values: DetailedPostsModel) => {
    if (values?.name_uz) {
      setActiveKay("2");
    } else {
      setActiveKay("1");
    }
  };

  // back
  function onPrevPage() {
    navigate(-1);
  }

  // tab items
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `O’zbekcha`,
      forceRender: true,
      children: (
        <>
          <Form.Item name={"videouz"}>
            <div className={styles.add_new_top}>
              <Video
                form={form}
                name={"videouz"}
                data={data ? {
                  name: data?.name_uz,
                  path: data?.videouz_path
                } : undefined}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="category_id"
            rules={[{required: true, message: ""}]}
            label="Kategoriyalar"
            className={styles.add_new_category}
          >
            <Select
              placeholder={"Kategoriya tanlang"}
              className={styles.select}
            >
              {categories?.map((item: any) => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name_uz}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="name_uz"
            rules={[{required: true, message: ""}]}
            label="Sarlavha"
            className={styles.add_new_title}
          >
            <Input className={styles.input}></Input>
          </Form.Item>

          <Form.Item name="html_uz">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
            />
          </Form.Item>
        </>
      ),
    },
    {
      key: "2",
      label: `Русский`,
      forceRender: true,
      children: (
        <>
          <Form.Item name={"videoru"}>
            <div className={styles.add_new_top}>
              <Video
                form={form}
                name={"videoru"}
                data={data ? {
                  name: data?.name_ru,
                  path: data?.videoru_path
                } : undefined}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="category_id"
            rules={[{required: true, message: ""}]}
            label="Категории"
            className={styles.add_new_category}
          >
            <Select placeholder={"Выберите опцию"} className={styles.select}>
              {categories?.map((item: any) => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name_ru}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="name_ru"
            rules={[{required: true, message: ""}]}
            label="Заголовок"
            className={styles.add_new_title}
          >
            <Input className={styles.input}/>
          </Form.Item>
          <Form.Item name="html_ru">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
            />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div className={styles.add_new}>
      <div className={styles.header}>
        <div className={styles.header_left}>
          <div onClick={onPrevPage}>
            <ArrowLeft/>
          </div>
          <span>{pageTitle}</span>
        </div>
      </div>

      <Form
        layout="vertical"
        form={form}
        className={styles.add_new_form}
        onFinishFailed={(e) => onFinishFailed(e.values)}
        onFinish={onFinish}
      >
        <Spin spinning={isLoading && !isCreating}>
          <Tabs
            defaultActiveKey="1"
            items={items}
            activeKey={activeKay}
            onChange={(e) => setActiveKay(e)}
            className={styles.add_new_tab}
          />
          <div className={styles.add_new_buttons}>
            <Button className={styles.cancel}>Bekor qilish</Button>
            <Button
              className={styles.submit}
              htmlType="submit"
              loading={createPosts.isLoading || editPost.isLoading}
            >
              Chop etish
            </Button>
          </div>
        </Spin>
      </Form>
    </div>
  );
}

export default Index;
