import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/constant/endPoints";
import {rootPaths} from "../../../routing/root/rootPaths";
import {message} from "antd";

// post create
export function useCreatePost() {
    const navigate = useNavigate();

    return useMutation(
        async (req: any) => {
            const res = await $api.post(endPoints.DETAILED_POST_CREATE, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                navigate(rootPaths.POSTS);
            },
            onError: () => {
                message.error("Error");
            },
        }
    );
}

// update post
export function useUpdatePost() {
    const navigate = useNavigate();

    return useMutation(
        async (req: FormData) => {
            const res = await $api.post(endPoints.DETAILED_POST_UPDATE, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                navigate(rootPaths.POSTS);
            },
            onError: () => {
                message.error("Error");
            },
        }
    );
}

