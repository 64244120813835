import axios from "axios";

export const BASE_URL = "https://backend.help.uysot.uz/api/dev/";

const $api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export default $api;
