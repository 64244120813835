import {createSlice} from '@reduxjs/toolkit'

export const categorySlice = createSlice({
   name: 'counter',
   initialState: {
      edit: {
         description_ru: "asdfjalksj",
         description_uz: "asdfjalsk;d",
         id: 39,
         name_ru: "kasjdlkfjas",
         name_uz: "jaklsdjg",
         place: 1
      }
   },
   reducers: {
      setCategoryEdit: (state, action) => {
         state.edit = action.payload
      }
   },
})

export const {setCategoryEdit} = categorySlice.actions

export default categorySlice.reducer
