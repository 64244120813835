import React from 'react';
import {ConfigProvider} from "antd";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import RootRouter from "./routing/root/RootRouter";

import {store} from "./store/store"

import './styles/main.scss'

const queryClient = new QueryClient()

function App() {
    return (
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#00A389',
                    }
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <RootRouter/>
                    </BrowserRouter>
                </QueryClientProvider>
            </ConfigProvider>
        </Provider>
    );
}

export default App;
