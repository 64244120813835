import React from 'react';

const UysotLogo = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.78204 28.5129C3.78204 27.0401 4.98251 25.8462 6.46336 25.8462H26.1264C27.6072 25.8462 28.8077 27.0401 28.8077 28.5129C28.8077 29.9856 27.6072 31.1795 26.1264 31.1795H6.46336C4.98251 31.1795 3.78204 29.9856 3.78204 28.5129Z"
                  fill="#00A389"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.6144 1.61019C15.6558 0.557346 17.3442 0.557346 18.3856 1.61019L31.719 15.09C32.7603 16.1429 32.7603 17.8499 31.719 18.9027C30.6776 19.9556 28.9891 19.9556 27.9477 18.9027L16.5 7.32921L5.05228 18.9027C4.01089 19.9556 2.32245 19.9556 1.28105 18.9027C0.23965 17.8499 0.23965 16.1429 1.28105 15.09L14.6144 1.61019Z"
                  fill="#00A389"/>
            <path
                d="M21.0128 18.6668C21.0128 21.0459 18.9923 22.9745 16.4999 22.9745C14.0076 22.9745 11.9871 21.0459 11.9871 18.6668C11.9871 16.2878 14.0076 14.3591 16.4999 14.3591C18.9923 14.3591 21.0128 16.2878 21.0128 18.6668Z"
                fill="#FFCF9E"/>
        </svg>

    );
};

export default UysotLogo;