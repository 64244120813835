import React from 'react';

const CategoriesIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 20V10M12.5 20V4M6.5 20V14" stroke="#344054" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>


    );
};

export default CategoriesIcon;