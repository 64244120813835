import {useLocation} from "react-router-dom";
import {endPoints} from "../utils/constant/endPoints";
import querystring from "query-string";
import {useQuery} from "@tanstack/react-query";
import {Pagination} from "../../../utils/models/paginationType";
import {FaqModel} from "../../../utils/models/faqModel";
import {queryKeys} from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import {message} from "antd";

export function useGetFaq() {
   const location = useLocation()
   let url = endPoints.FAQ
   const searchParams = querystring.parse(location.search)
   url += `?${querystring.stringify({...searchParams, size: 10})}`

   return useQuery<Pagination<FaqModel>>(
      [queryKeys.FAQ, url],
      async () => {
         const res = await $api.get(url)
         return res.data
      }, {
         onError: () => {
            message.error('Error')
         }
      }
   )
}